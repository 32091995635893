
import { Component, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";

@Component
export default class MixinMemberDeliveryCouponDialogValidator extends Vue {
  @Validations()
  validations = {
    item: {
      //クーポン
      coupon: { required },
    },
    //有効期限
    expirationDate: { required },
  };
  //---------------------------
  // methods
  //---------------------------

  /**
   * クーポンの入力エラーメッセージを返します.
   */
  get couponErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.coupon?.$dirty) return errors;
    !this.$v.item.coupon?.required &&
      errors.push("クーポンを入力してください.");
    return errors;
  }
  /**
   * 有効期限の入力エラーメッセージを返します.
   */
  get expirationDateErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.expirationDate?.$dirty) return errors;
    !this.$v.expirationDate?.required &&
      errors.push("有効期限を入力してください.");
    return errors;
  }
}
