import { DeliverdCoupon, Member } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * 配布済みクーポンに関する機能を提供します.
 */
class DeliverdCouponService {
  /**
   * 初期状態の配布済みクーポン
   */
  public get defaultDeliverdCoupon(): DeliverdCoupon {
    return {
      guid: "",
      couponGuid: "",
      couponTitle: "",
      couponAmount: 0,
      couponSlugName: "",
      couponDescription: "",
      couponMinPriceToGain: 0,
      couponMinPriceToUse: 0,
      createdAt: "",
      updatedAt: "",
      coupon: undefined,
      member: undefined,
      expirationDate: null,
      isUsed: false,
      usedAt: null,
    } as DeliverdCoupon;
  }


  /**
   * 会員を指定して、すべての配布済みクーポンを取得して返します.
   * @returns すべてのクーポン
   */
  public async allDeliverdCoupons(memberGuid: string) {
    const response = await sdk.deliverdCoupons({
      filter: {
        memberGuid: memberGuid
      }
    });
    if (response?.deliverdCoupons) {
      return response.deliverdCoupons as DeliverdCoupon[];
    }
    return [] as DeliverdCoupon[];
  }

  /**
   * 指定したGUIDに一致するクーポンを取得して返します.
   * @param guid クーポンのGUID
   * @returns クーポン
   */
  public async getDeliverdCoupon(guid: string) {
    const response = await sdk.deliverdCoupon({
      guid: guid
    });
    return response.deliverdCoupon as DeliverdCoupon;
  }

  /**
   * クーポンを登録します.（IDは自動採番）
   * @param input クーポンの登録情報
   * @returns 登録完了後のクーポン
   */
  public async createDeliverdCoupon(input: DeliverdCoupon) {
    try {
      const response = await sdk.createDeliverdCoupon({
        input: {
          couponGuid: input.coupon?.guid,
          couponTitle: input.coupon?.title,
          couponSlugName: input.coupon?.slugName,
          couponAmount: input.coupon?.amount,
          couponDescription: input.coupon?.description,
          couponMinPriceToGain: input.coupon?.minPriceToGain,
          couponMinPriceToUse: input.coupon?.minPriceToUse,
          memberGuid: input.member?.guid,
          expirationDate: input.expirationDate,
          isUsed: input.isUsed,
          usedAt: input.usedAt,
        },
      });

      if (response?.createDeliverdCoupon) {
        return response.createDeliverdCoupon;
      }
    } catch (err) {
      console.log(err);
    }
  }


  /**
   * クーポンを物理削除します.
   * @param input クーポンの削除情報
   * @returns 削除完了後のクーポン
   */
   public async deleteDeliverdCoupon(input: DeliverdCoupon) {
    try {
      const response = await sdk.deleteDeliverdCoupon({
        deleteDeliverdCouponInput: {
          guid: input.guid,
        },
      });

      if (response?.deleteDeliverdCoupon) {
        return response.deleteDeliverdCoupon;
      }
    } catch (err) {
      console.log(err);
    }
  }

}

export default new DeliverdCouponService();
