









































import { DeliverdCoupon } from "@/graphql/client";
import { Component, Emit, Vue } from "vue-property-decorator";
import deliverdCouponService from "@/service/deliverdCouponService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class MemberDeliverdCouponDeleteDialog extends Vue {
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: DeliverdCoupon = deliverdCouponService.defaultDeliverdCoupon;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  /**
   * ダイアログを表示します.
   */
  public open(item: DeliverdCoupon): void {
    this.item = item;

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  deleteItem(): void {
    this.isProgressing = true;
    //delete
    deliverdCouponService
      .deleteDeliverdCoupon(this.item)
      .then((result) => {
        this.isProgressing = false;
        this.notifySuccess(result as DeliverdCoupon);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /**
   * 処理成功
   */
  @Emit("onSuccess")
  public notifySuccess(deleted: DeliverdCoupon): DeliverdCoupon {
    return deleted;
  }
}
