










import DefaultPageTemplate from "../../templates/DefaultPageTemplate.vue";
import MemberList from "@/components/organisms/member/MemberList.vue";

export default {
  components: {
    DefaultPageTemplate,
    MemberList,
  },
};
